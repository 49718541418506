import React from 'react';
import { Result } from 'antd';

const Info = ({ status, title, description }) => {
    return (
        <React.Fragment>
            <Result
                status={status}
                title={title}
                subTitle={<div dangerouslySetInnerHTML={{ __html: description }} />}
            >
            </Result>
        </React.Fragment>
    );
};

export default Info;
