import React, { useContext } from 'react';
import * as ICONS from '@ant-design/icons';
import axios from 'axios';
import AppContext from '../contexts/AppContext';
import './Card.css';
import { useNavigate } from "react-router-dom";

const Card = ({ id, name, description/*, link*/ }) => {
    const { AuthUser } = useContext(AppContext);

    const navigate = useNavigate();

    const Default = () => <ICONS.SettingFilled />;
    const FolderAddFilled = () => <ICONS.FolderAddFilled />;
    const IdcardFilled = () => <ICONS.IdcardFilled />;
    const EuroCircleFilled = () => <ICONS.EuroCircleFilled />;
    const CreditCardFilled = () => <ICONS.CreditCardFilled />;

    const IconsComponents = {
        0: Default,
        4: CreditCardFilled,
        24: FolderAddFilled,
        25: FolderAddFilled,
        26: FolderAddFilled,
        29: IdcardFilled,
        31: EuroCircleFilled,
        35: CreditCardFilled,
    }

    const Icon = id in IconsComponents ? IconsComponents[id] : IconsComponents[0];

    const getLink = (event) => {
        event.preventDefault();

        axios.post(`${process.env.REACT_APP_API_HOST}/service/link`, { folder: AuthUser.folder, mail: AuthUser.mail, service: id }).then(result => {
            const response = result.data;

            if (response.link.hasOwnProperty('ErrorCode')) {
                return navigate('/error');
            }

            window.open(response.link);
        }).catch(error => {
            console.log(error);
        });
    }

    return (
        <React.Fragment>
            <button className='card' /*href={link}*/ onClick={(event) => getLink(event)} /*target='_blank' rel='noreferrer'*/>
                <div className='icon'>
                    <Icon />
                </div>
                <div className='title'>{name}</div>
                <div className='description'>{description}</div>

            </button>
        </React.Fragment>
    );
};

export default Card;
