import React, { useContext, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import AppContext from '../../contexts/AppContext';
import axios from 'axios';
import Loading from '../../components/Loading';
import './Auth.css';

const Auth = () => {
    const { setAuthUser } = useContext(AppContext);

    const navigate = useNavigate();

    const { folder, timestamp, token } = useParams();

    useEffect(() => {
        axios.post(`${process.env.REACT_APP_API_HOST}/auth`, { folder, timestamp, token }).then(result => {
            setAuthUser(result.data);
            navigate('/services');
        }).catch(error => {
            console.log(error);
        });
        // eslint-disable-next-line
    }, []);

    return (
        <React.Fragment>
            <div className='content-auth'>
                <Loading />
            </div>
        </React.Fragment>
    );
};

export default Auth;
