import React from 'react';
import { Result } from 'antd';
import './Error.css';

const Error = () => {

    return (
        <React.Fragment>
            <div className='content-error'>
                <Result
                    status="error"
                    title="Une erreur est survenue"
                    subTitle="Nous ne sommes pas parvenus à vous authentifier"
                >
                </Result>
            </div>
        </React.Fragment>
    );
};

export default Error;
