import React from 'react';
import './Loading.css';

const Loading = () => {
    return (
        <React.Fragment>
            <svg viewBox="0 0 33.515 31.648">
                <g transform="translate(0)">
                    <path className="icon-a" d="M4650,4060h7.543l.389,5.132,2.644,5.288-2.021.466-.622,4.821H4650Z"
                        transform="translate(-4650 -4060)" />
                    <path className="icon-b" d="M4650,4060h7.543l.389,5.132,2.644,5.288-2.021.466-.622,4.821H4650Z"
                        transform="translate(-4634.836 -4060)" />
                    <path className="icon-c" d="M4650,4060h7.543l.389,5.132,2.644,5.288-2.021.466-.622,4.821H4650Z"
                        transform="translate(-4642.224 -4044.059)" />
                    <path className="icon-d" d="M4650,4060h7.543l.389,5.132,2.644,5.288-2.021.466-.622,4.821H4650Z"
                        transform="translate(-4627.061 -4044.059)" />
                </g>
            </svg>
        </React.Fragment>
    );
};

export default Loading;
