import React, { useContext } from 'react'
import { Navigate } from 'react-router-dom'
import AppContext from '../contexts/AppContext';

const PrivateRouting = ({ children }) => {
    const { AuthUser } = useContext(AppContext);

    if (AuthUser === false || AuthUser === null) {
        return <Navigate to="error" />;
    } else if (AuthUser.error) {
        return <Navigate to="info" />;
    } else {
        return children;
    }
}

export default PrivateRouting;