import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import PrivateRouting from './PrivateRouting';
import Services from '../pages/services/Services';
import Auth from '../pages/auth/Auth';
import Error from '../pages/error/Error';
import Info from '../pages/info/Info';

const Routing = () => {
    return (
        <BrowserRouter>
            <Routes>
                <Route path="auth/:folder/:timestamp/:token" element={<Auth />} />

                <Route path="error" element={<Error />} />

                <Route path="info" element={<Info />} />

                <Route path="*" element={
                    <PrivateRouting>
                        <Services />
                    </PrivateRouting>
                } />
            </Routes>
        </BrowserRouter>
    );
}

export default Routing;