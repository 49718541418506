import React, { useContext } from 'react';
import { Navigate } from 'react-router-dom'
import AppContext from '../../contexts/AppContext';
import Warning from '../../components/Warning';
import './Info.css';

const Info = () => {
    const { AuthUser } = useContext(AppContext);

    if (!AuthUser) return <Navigate to="../error" />;

    return (
        <React.Fragment>
            <div className='content-info'>
                <Warning
                    status='info'
                    title={AuthUser.errorTitle}
                    description={AuthUser.errorMessage} />
            </div>
        </React.Fragment>
    );
};

export default Info;
