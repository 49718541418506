
import React, { useState } from 'react';
import AppContext from './contexts/AppContext';
import Routing from './routes/Routing';
import { ConfigProvider } from 'antd';
import frFR from 'antd/lib/locale/fr_FR';
import 'antd/dist/antd.css';
import './App.css';

const App = () => {
  const [AuthUser, setAuthUser] = useState(null);

  const value = { AuthUser, setAuthUser };

  return (
    <ConfigProvider locale={frFR}>
      <AppContext.Provider value={value}>
        <Routing />
      </AppContext.Provider>
    </ConfigProvider>
  );
};

export default App;
